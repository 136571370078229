<template>
  <div class="">
    <div class="flex justify-between items-center mb-6 border-b pb-6">
      <div>
        <h1 class="text-4xl">New Reconcilation</h1>
        <!-- <p class="text-sm text-gray-400">Welcome to your dashboard</p> -->
      </div>
    </div>

    <div class="flex flex-col">
      <form class="bg-white shadow rounded-md p-5" @submit.prevent="onSubmit">
        <h1 class="px-3 text-2xl font-bold mb-4">Reconcilation Detailes</h1>
        <div class="flex flex-wrap overflow-hidden">
          <div class="w-full overflow-hidden xl:my-3 xl:px-3 xl:w-1/2">
            <!-- Column Content -->
            <div class="mb-4">
              <label
                class="block text-gray-700 text-sm font-normal mb-2"
                for="username"
              >
                Date
              </label>
              <input
                class="
                  shadow
                  appearance-none
                  border
                  rounded
                  w-full
                  py-2
                  px-3
                  text-gray-700
                  leading-tight
                  focus:outline-none focus:shadow-outline
                "
                @change="loadData()"
                v-model="form.date"
                type="date"
                :class="{
                  'is-invalid': form.errors.has('date'),
                }"
                required
              />
            </div>
          </div>
        </div>
        <div class="w-full text-center my-3">
          <p class="text-red-600" v-if="showTopError">{{ topErrorMessage }}</p>
        </div>
        <hr />

        <template v-if="form.date">
          <div class="flex flex-col justify-center my-10">
            <h1 class="font-basic text-xl text-center">
              Start reconciliation for <b>{{ form.date }}</b>
            </h1>

            <p class="text-center text-gray-600">
              Total collections of {{ form.collections.length }} -
              {{ formatPrice(totalAmount) }}
            </p>
            <div
              v-if="form.collections.length > 0"
              class="w-full flex justify-center my-5 overflow-hidden"
            >
              <button
                class="
                  px-4
                  py-2
                  rounded
                  text-white
                  inline-block
                  shadow-lg
                  bg-green-500
                  hover:bg-green-600
                  focus:bg-green-700
                "
                :class="{ 'opacity-50 cursor-not-allowed': isLoading }"
                type="submit"
                :disabled="isLoading"
              >
                <span v-if="isLoading">Loading...</span>
                <span v-else>Click here to start reconciliation</span>
              </button>
            </div>
          </div>
        </template>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Form from "vform";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import checkPermissionExist from "../../../mixins/checkPermissionExist";

export default {
  middleware: "auth",

  layout: "default",
  components: {},

  mixins: [checkPermissionExist],

  data: () => ({
    isLoading: false,
    cashcollections: null,

    form: new Form({
      date: null,

      bank: "VISTA BANK",
      collections: [],
    }),

    branchesSales: null,
    showTopError: false,
    topErrorMessage: "",
  }),

  computed: {
    ...mapGetters({
      user: "auth/user",
    }),

    totalAmount: function () {
      let total = [];

      Object.entries(this.form.collections).forEach(([key, val]) => {
        console.log(key);
        total.push(val.total_deposited); // the value of the current key.
      });

      return total.reduce(function (total, num) {
        return total + num;
      }, 0);
    },
  },

  created() {
    if (!this.checkPermissionExist(this.user.data, "reconciliations-create")) {
      this.$router.go(-1);
      return false;
    }
  },

  mounted() {
    this.loadBranches();
  },

  methods: {
    async loadData() {
      if (!this.form.date) {
        this.showTopError = true;
        this.topErrorMessage = "Please select date to continue";

        return false;
      }

      this.showTopError = false;

      try {
        const { data } = await axios.get(
          `/newreconcilation?date=${this.form.date}`
        );

        this.cashcollections = data.data;

        this.cashcollections.forEach((item, index) => {
          console.log(index);
          this.form.collections.push({
            id: item.id,
            bank: item.bank,
            bank_slip_number: item.bank_slip_number,
            total_deposited: item.total_deposited,
            referance_number: "",
          });
        });

        return true;
      } catch (e) {
        console.log("Error");

        return false;
      }
    },

    async onSubmit() {
      // Submit the form
      this.isLoading = true;

      try {
        const { data } = await this.form.post("/reconciliation/store");

        if (data.status == "55") {
          Swal.fire({
            icon: "warding",
            title: "Please make sure all branches have made their collection",
          });

          return false;
        }

        this.isLoading = false;
        this.$router.push({ path: `/reconciliation/manage/${data.id}` });

        return true;
      } catch (e) {
        this.isLoading = false;

        console.log("Error");
        return false;
      }
    },

    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
